


































import { Component, Vue, Watch, Mixins } from 'vue-property-decorator'
import SidebarSwitcher from '@/components/organisms/v2/SidebarSwitcher.vue'
import TestStudentHistoryHeader from '@/components/organisms/TestStudentHistoryHeader.vue'
import ColoredBox from '@/components/atoms/ColoredBox.vue'
import SubjectProgress from '@/components/organisms/SubjectProgress.vue'
import SelectBase from '@/components/atoms/SelectBase.vue'
import { ResultProgressesResponseType } from '@/models/api/resultProgresses'
import { SubjectProgressType, StepProgressMethods } from '@/mixins/utils/StepProgressMethods'
import ClassModeChangeAble from '@/mixins/student/ClassModeChangeAble'

@Component({
  components: {
    SidebarSwitcher,
    TestStudentHistoryHeader,
    ColoredBox,
    SubjectProgress,
    SelectBase,
  },
})
export default class StepSituation extends Mixins(StepProgressMethods, ClassModeChangeAble) {
  private userId = Vue.prototype.$cookies.get('authGdls').currentApiUserId

  private hasTerm = true

  private selectTestOptions: { text: string; value: number }[] = []
  private selectedTest = 0
  private subjectSteps: SubjectProgressType[] = []

  @Watch('selectedTest')
  onSelectedTestChange() {
    this.getResultProgresses()
  }

  private async getTermExamsTerms() {
    await Vue.prototype.$http.httpWithToken
      .get('/termExams/terms', { params: { userId: this.userId } })
      .then((res: any) => {
        if (res.data.length === 0) {
          this.hasTerm = false
          return
        }
        this.selectTestOptions = res.data.map((term: { gradeName: string; termName: string; id: number }) => {
          return { text: `${term.gradeName} ${term.termName}`, value: term.id }
        })
        const selectedId = res.data.find((term: { isSelected: boolean }) => {
          return term.isSelected === true
        })
        this.selectedTest = selectedId.id
      })
  }

  private async mounted(): Promise<void> {
    await this.checkClassModeAndSideMenuMode()
    await this.getTermExamsTerms()
  }

  private async getResultProgresses() {
    if (!this.selectedTest) return
    await Vue.prototype.$http.httpWithToken
      .get(`/history/resultProgresses/${this.userId}`, { params: { termExamId: this.selectedTest } })
      .then(({ data }: { data: ResultProgressesResponseType }) => {
        this.subjectSteps = this.parseStepProgresses(data)
      })
      .catch(() => {
        this.subjectSteps = []
      })
  }
}
